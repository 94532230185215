import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Notification from './components/Notification';
import Dashboard from './components/Dashboard';
import Chart from './components/Chart';
import Login from './components/Login';
import Home from './components/Home';
function App() {
  return (
    
    <>
    <Router>
      <Switch>

        <Route exact path="/"><Home/></Route>
        <Route  path="/dashBoard"><Dashboard/></Route>
        <Route  path="/notification"><Notification/></Route>
        <Route  path="/login"><Login/></Route>
        <Route  path="/chart"><Chart/></Route>
       
      </Switch>
    </Router>
     
    </>
  );
}

export default App;
