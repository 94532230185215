import React, { Component } from 'react';
import Login from '../components/Login'
import Home from '../components/Home'
import { BrowserRouter as Router, Switch, Route , NavLink } from 'react-router-dom';


function Header() {
    return ( 
        <>
        
            <nav class="navbar navbar-expand-lg navbar-light" id='main-navigation'>
                <div class="container">
                    <div className='site-brand'> 
                        <a class="navbar-brand" href="/Home"><img src="/images/couponginiLogo.svg" alt="Site Logo"  className='w-75'/></a>
                    </div>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                    <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="mynavbar">
                            <ul class="navbar-nav mx-auto">
                                <li class="nav-item">
                                <NavLink exact activeClassName="active" className="nav-link" to='/' >Home</NavLink>
                                </li>
                                <li class="nav-item">
                                <NavLink exact activeClassName="active" className="nav-link" to='/home'>About</NavLink>
                                </li>
                                <li class="nav-item">
                                <NavLink exact activeClassName="active" className="nav-link" to='/home'>Services</NavLink>
                                </li>
                                <li class="nav-item">
                                <NavLink exact activeClassName="active" className="nav-link" to='/home'>Blog</NavLink>
                                </li>
                                <li class="nav-item">
                                <NavLink exact activeClassName="active" className="nav-link" to='/home'>Contact Us</NavLink>
                                </li>
                            </ul>
                            <ul className='list-unstyled ms-auto mb-0'>
                                <li><NavLink exact activeClassName="active" to='/Login' className='grn-btn'>Login</NavLink></li>
                            </ul>

                                
                       
                    </div>
                </div>
            </nav>

            

           
        </>
     );
}


export default Header;