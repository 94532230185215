import React from 'react';
import {Bar,Pie} from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
  } from 'chart.js';
  
  ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
  );

  function setGraphValue(data) {
   return  {
      labels: ['coupongini', 'avmnewslive', 'jobhuntley',
               'dealimagine'],
      datasets: [
        {
          label: 'Website',
          backgroundColor: '#bbeab4',
          borderColor: '#55b946',
          borderWidth: 1,
          data: [data.coupongini,data.avm,data.jobhuntley, 0],
          barPercentage: 0.5,
        }
      ]
    }
  }

  function setpieGraph(data) {
    return {
      labels: ['MobileSubs', 'DesktopSubs'],
      datasets: [
        {
          label: '# of Votes',
          data: [data.mobileSubs,data.desktopSubs],
          backgroundColor: [
            '#238DE4',
            '#E88E28'
          ],
          borderWidth: 1,
        },
      ],
    };
  }



function GraphData(props) {
    return (
      <div style={{display:'flex'}}>
        <Bar
          data={setGraphValue(props)}/>
          
          <div className="pichart-div"> <Pie
          data={setpieGraph(props)}
        /></div>
          
      </div>
    );
  }
  export default GraphData;