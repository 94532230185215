import React, { useState } from 'react';
import {NavLink} from 'react-router-dom'
function Sidebar() {
 

    return ( 
        <React.Fragment>
                 <div className="left-sidebar">
                        <div className="logo">
                            <img src="/images/couponginiLogo.svg" alt="logo" />
                        </div>
                        <div className="sidebar-options">
                            <p>MENU</p>

                            <ul id='nav-list'>
                                <li><NavLink exact activeClassName="active" to='/DashBoard' ><i className="fa fa-th-large" aria-hidden="true"></i>Dashboard</NavLink></li>
                                <li><NavLink activeClassName="active" to='/Notification'><i className="fa fa-bell" aria-hidden="true"></i>Notifications</NavLink></li>
                                <li><NavLink activeClassName="" to='/DashBoard'><i className="fa fa-calendar-check-o" aria-hidden="true"></i>Scheduled Notifications</NavLink></li>
                                <li><NavLink activeClassName="" to='/DashBoard'><i className="fa fa-list-alt" aria-hidden="true"></i>Notifications Reports</NavLink></li>
                                <li><NavLink activeClassName="" to='/DashBoard'><i className="fa fa-users" aria-hidden="true"></i>Create Audience</NavLink></li>
                            </ul>

                        </div>
                    </div>
        </React.Fragment>
     );
}

export default Sidebar;