import React, { useState } from 'react';
import Sidebar from './Sidebar';
const axios = require('axios');
const Notification = () => {
    const [noteTitle, enteredNoteTitle] = useState('')
    const [noteMessage, enteredNoteMessage] = useState('')
    const [noteUrl, enteredNoteUrl] = useState('')
    const [btn1Text, enteredBtn1Text] = useState('')
    const [btn2Text, enteredBtn2Text] = useState('')
    const [noteTags, enteredNoteTags] = useState('')
    const [iconImage, setIconImg] = useState('')
    const [largeImageUrl, setLargeImgUrl] = useState()
    const [largeImageFile, setLargeImgFile] = useState('')
    const [iconImageFile, setIconImgFile] = useState('')
    const [isTag, setIsTag] = useState(false)
    const [isActionBtn, setIsActionBtn] = useState(false)
    const [country, setcountry] = useState('')
    const [website, setWebsite] = useState('')
    
    
    const sendNotification = () => {
        if(noteTitle === '' || noteMessage === ''){
            alert("Please Enter Title & Body")
        }else if(website === 'None' || website === ''){
            alert("Please select website")
        }else{
            
            var mainObject = {}
            var optionsBody = {}
            var actions = []
            var buttons = {}
            var buttons2 = {}
            mainObject.title = noteTitle
            optionsBody.body = noteMessage
            optionsBody.icon = iconImage
            optionsBody.image = largeImageUrl
            if(noteUrl === ''){
            mainObject.url = "https://www.coupongini.com"
            }else{
                mainObject.url = noteUrl
            }
            if(isTag){
              if(noteTags === ''){
                  alert("Please enter tag or off the value")
                  return
              }  
              optionsBody.tag = noteTags 
            }else{
                delete optionsBody.tag 
            }
            if(isActionBtn){

            if(btn1Text === '' && btn2Text === ''){
                alert("Please enter one button value or close the menu")
                return
            }
            else if(btn1Text === ''){
                buttons.title = btn2Text
                buttons.icon = "icon.png"
                buttons.action = "explore"
                actions.push(buttons)
            }
            else if(btn2Text === '') {
                buttons.title = btn1Text
                buttons.icon = "icon.png"
                buttons.action = "explore"
                actions.push(buttons)
            }else{
                buttons.title = btn1Text
                buttons.icon = "icon.png"
                buttons.action = "explore"
                buttons2.title = btn2Text
                buttons2.icon = "icon.png"
                buttons2.action = "explore"
                actions.push(buttons)
                actions.push(buttons2)

            }   
            }else{
                actions = []
            }

            if(actions.length == 0){
                let options = {...optionsBody}
               mainObject = {...mainObject,options} 

            }else{
                let options = {...optionsBody,actions}
                mainObject = {...mainObject,options} 
            }

            console.log(mainObject);
            callApi(mainObject)
        }
        
    }


    function callApi(body) {
        console.log(JSON.stringify(body));
       var notificationUrl = null
       if(country === 'All' || country === ''){
        notificationUrl = `http://api.coupongini.com/v1/sendnotifi/all?site=`+website
       }else{
        notificationUrl = `http://api.coupongini.com/v1/sendnotifi/all?site=`+website+"&country="+country
       }

       alert(notificationUrl)
        
        fetch(notificationUrl, {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(body)
         }).then(res =>{
            console.log(res.status);
            // var elem = ''
            // invalidMessage.current.append(elem);
             if(res.status == 200){
                alert("Gya notification")
                
             }else if(res.status == 400){
               alert("Error agya")
             }

             
             
             else{
                //  elem = 'Please enter a valid email!'
                //  invalidMessage.current.append(elem);
                alert("kuch pnga ho gya")
                 
    
             }
             
         });
         
    }


    const uploadFile = () => {
        let formData = new FormData();
        formData.append(`img`, largeImageFile);
        axios.post(`https://api.coupongini.com/v1/uploadpic`, formData, {
            headers: {
             "Authorization" : `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MWNjNGZhYTI1MDAwZTFmNTVlYTU5YWQiLCJpYXQiOjE2NDQ5ODkwNjR9.yn5-JZLs0-jLAUW_NUOL-f12DGzsM5K78n9EIOs3v3k`,
              "Content-Type": "multipart/form-data",
            },
          }).then((response) => {
            fnSuccess(response);
          }).catch((error) => {
            fnFail(error);
          });
      };
      const fnSuccess = (response) => {
        //Add success handling
        setLargeImgUrl("https://api.coupongini.com/"+response.data[0])
        alert("Hogyi image upload")
      };
      
      const fnFail = (error) => {
        //Add failed handling
        alert("Nhi hui")
      };


      const uploadIconFile = () => {
        let formData = new FormData();
        formData.append(`img`, iconImageFile);
        axios.post(`https://api.coupongini.com/v1/uploadpic`, formData, {
            headers: {
             "Authorization" : `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MWNjNGZhYTI1MDAwZTFmNTVlYTU5YWQiLCJpYXQiOjE2NDQ5ODkwNjR9.yn5-JZLs0-jLAUW_NUOL-f12DGzsM5K78n9EIOs3v3k`,
              "Content-Type": "multipart/form-data",
            },
          }).then((response) => {
            setIconImg("https://api.coupongini.com/"+response.data[0])  
            alert("Hogyi image upload")
          }).catch((error) => {
            fnFail(error);
          });
      };


    const getImage = (e) => {
        let file = e.target.files[0];
        console.log(file);
        setLargeImgFile(file)
      };
      const getuploadIconImage = (e) => {
        let file = e.target.files[0];
        setIconImgFile(file)
       
        
      };


    const getIconImage = (event) => {
        // var icons = document.querySelectorAll('.icon-img')
        // for(let i=0;i<icons.length;i++){
        //     if(icons[i].classList.contains('activeIcon')){
        //         icons[i].classList.remove('activeIcon')
        //     }
        // }
        // event.target.classList.add('activeIcon')
        //var iconImages = "http://localhost:3000" + event.target.getAttribute("src")
      // var iconImages = "https://api.coupongini.com/uploads/images/ZAActiveStorage_VS/purevpn.webp"
       // setIconImg(iconImages)
    }
    // const getLargeImage = (event) => {
    //     var images = document.querySelectorAll('.large-img')
    //     for(let i=0;i<images.length;i++){
    //         if(images[i].classList.contains('activeIcon')){
    //             images[i].classList.remove('activeIcon')
    //         }
    //     }
    //     event.target.classList.add('activeIcon')
    //     var largeImages = "http://localhost:3000" + event.target.getAttribute("src")
    //     setLargeImg(largeImages)
    // }
    
    
    const imageAddDropDown = (event) => {
        if(document.getElementById('dropdown1').classList.contains('active-dropdown')){
            document.getElementById('dropdown1').classList.remove('active-dropdown')
        }else{
            document.getElementById('dropdown1').classList.add('active-dropdown')
        }
    }
    const addButtonDropDown = (event) => {
        if(document.getElementById('dropdown2').classList.contains('active-dropdown')){
            document.getElementById('dropdown2').classList.remove('active-dropdown')
            setIsActionBtn(false)
        }else{
            document.getElementById('dropdown2').classList.add('active-dropdown')
            setIsActionBtn(true)
        }
    }
    const tagDropDown = (event) => {
        if(document.getElementById('dropdown3').classList.contains('active-dropdown')){
            document.getElementById('dropdown3').classList.remove('active-dropdown')
            setIsTag(false)
            
        }else{
            document.getElementById('dropdown3').classList.add('active-dropdown')
            setIsTag(true)
        }
    }
    const getNotificationTitle = (event) => {
        enteredNoteTitle(event.target.value)
    }
    const getNotificationMessage = (event) => {
        enteredNoteMessage(event.target.value)
    }
    const getNotificationUrl = (event) => {
        enteredNoteUrl(event.target.value)
    }
    const getNotificationButtonText = (event) => {
        enteredBtn1Text(event.target.value)
    }
    const getNotificationButtonUrl = (event) => {
        enteredBtn2Text(event.target.value)
    }
    const getNotificationTags = (event) => {
        enteredNoteTags(event.target.value)
    }
    
    const selectCountry = (event) => {
        setcountry(event.target.value)
;    };
    const selectWebsite = (event) => {
        setWebsite(event.target.value)

    };
    
    

    // const addMoreButtons = () => {
    //     document.getElementById('buttonDiv1').innerHTML += `
    //         <label>Button | Text:</label>
    //         <input type="text" placeholder="Enter Button | Title"/>
    //         <label>Button | Landing Url:</label>
    //         <input type="text" placeholder="Enter Button | Landing Url"/>
    //     `
    // }
    return (
        <>
            <div className="container-fluid" style={{paddingLeft:"0", paddingRight:"0", height:"100vh"}}>
                <div className="row notification-page-row">
                   <Sidebar/>
                    <div className="main-container pt-5 pb-5">
                        <h4>Create Notifications</h4>
                        <hr></hr>
                        <div class="d-flex mb-3">
                            <label>Notification Title:</label>
                            <input type="text" placeholder="TGIF Sale" onChange={getNotificationTitle}/>
                        </div>
                        <div class="d-flex mb-3">
                            <label>Notification Message:</label>
                            <input type="text" placeholder="Flat 20% Off on Shoes" onChange={getNotificationMessage} />
                        </div>
                        <div className='d-flex'>
                            <div className='left-width'>
                                <label className="notification-icon">Notification Icon:</label>
                                <p className="recommended-size">Recommended size(100*100)</p>
                            </div>
                            
                            <ul className="icon-notification" id="icons" style={{listStyle:"none", display:"flex"}}>
                                <li><img onClick={getIconImage} id="ghghj" className="icon-img" src={iconImage} alt="icon" style={{width:"80px"}}/></li>
                                <li className="fileUpload ms-3"><input class="upload" onChange={getuploadIconImage} type="file" id="formFile" /><span><img src="/images/plus.webp" className='add-icon'/></span></li>
                                <button onClick ={uploadIconFile} style={{marginLeft:30}}>Upload Icon</button>
                            </ul>
                        </div>
                       <div className='row'>
                            <div className='col-md-6 mb-3'>
                                    <label>Choose Website</label>
                                    <select class="form-select mt-2" aria-label="Default select example" onChange={selectWebsite}>
                                        <option  value="None">None</option>
                                        <option value="coupongini">coupongini</option>
                                        <option value="avmnewslive">avmnewslive</option>
                                        <option value="jobhuntley">jobhuntley</option>
                                        <option value="dealimagine">dealimagine</option>
                                    </select>    
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <label>Choose Country</label>
                                    <select class="form-select mt-2" aria-label="Default select example" onChange={selectCountry}>
                                        <option  value="All">All</option>
                                        <option value="IN">IN</option>
                                        <option value="CA">CA</option>
                                        <option value="CH">CH</option>
                                        <option value="UK">UK</option>
                                    </select>    
                                </div>
                        </div>
                        <div className='mb-3'>
                            <label className='mb-2'>Landing Page Url:</label>
                            <input type="text" placeholder="https://www.google.com" onChange={getNotificationUrl} />
                        </div>
                        <div className="toggle-box">
                            <div className="switch-tab">
                                <p>Add large Image to Notification</p>
                                <label className="switch" id="switch1">
                                    <input type="checkbox" onClick={imageAddDropDown}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div className="dropdown-on-toggle" id="dropdown1">
                            <ul className="icon-notification" style={{listStyle:"none", display:"flex"}}>
                            {
                                  largeImageUrl == null ? null :<li><img  className="large-img" src={largeImageUrl} alt="icon" style={{width:"300px"}}/></li>  
                            }
                                
                            </ul>
                            <input type="file" onChange={getImage} accept ="image/*"/>
                        <button onClick ={uploadFile}>Upload Icon</button>
                            </div>
                        </div>
                        <div className="toggle-box">
                            <div className="switch-tab">
                                <p>Add Buttons to Notification</p>
                                <label className="switch" id="switch2">
                                    <input type="checkbox" onClick={addButtonDropDown}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div className="dropdown-on-toggle" id="dropdown2">
                                <div id="buttonDiv1">
                                    <label className='mb-2'>Button 1 | Title</label>
                                    <input type="text" placeholder="Enter Button | Title" onChange={getNotificationButtonText}/>
                                    <br></br>
                                    <label className='mb-2'>Button 2 | Title</label>
                                    <input type="text" placeholder="Enter 2nd Button | Title" onChange={getNotificationButtonUrl}/>
                                </div>
                                {/* <button onClick={addMoreButtons}>Add Another Button</button> */}
                            </div>
                        </div>
                        <div className="toggle-box">
                            <div className="switch-tab">
                                <p>Add Tags to Notification</p>
                                <label className="switch" id="switch3">
                                    <input type="checkbox" onClick={tagDropDown}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div className="dropdown-on-toggle" id="dropdown3">
                                <label className='mb-2'>Tag:</label>
                                <input type="text" placeholder="Tag" onChange={getNotificationTags}/>
                            </div>
                        </div>
                        <button onClick={sendNotification}>Send Notification</button>
                        
                    </div>
                    <div className="right-sidebar">
                        <p>This is the preview of notification that you just created.</p>
                        <div className="note-preview">
                            <img src="/images/giniicon.png" alt="preview-img" style={{width:"50px",height:"50px",margin:"10px"}}/>
                            <div className="note-info">
                                <p>{noteTitle}</p>
                                <p>{noteMessage}</p>
                                <span>17:11 www.coupongini.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </>
    );
}

export default Notification;
