import React, { Component } from 'react';
import Footer from './Footer';
import Header from './Header';

function Home() {
    return ( 
        <>
        <Header />
        <section id='home-banner'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-7 col-md-8 col-sm-10'>
                        <div className='bg-white rounded shadow content-pos p-4 pb-5 p-md-5'> 
                            <h1>Email and Messenger Marketing Platform</h1>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            <a href="" className='py-3 grn-btn fs-5'>Contact Us</a>
                        </div>
                     </div>
                </div>
            </div>
        </section>
        <section id='browser-support'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-md-10 text-center'>
                        <h2>Web Push Notifications Are Supported in All Popular Browsers</h2>
                        <p className='my-3'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                    </div>
                </div>
                <div className='row justify-content-center justify-content-md-between browser-detail'>
                    <div className='col-md-4 col-sm-6 text-center mt-5 '>
                        <img src="/images/chrome.webp" alt="chrome icon"/>
                        <p className='my-4'>(Windows, Mac OS, Linux, Android) </p>
                    </div>
                    <div className='col-md-4 col-sm-6 text-center mt-5'>
                        <img src="/images/firefox.webp" alt="firefox icon"/>
                        <p className='my-4'>(Windows, Mac OS, Linux, Android) </p>
                    </div>
                    <div className='col-md-4 col-sm-6 text-center mt-5'>
                        <img src="/images/safari.webp" alt="safari icon"/>
                        <p className='my-4'>(Windows, Mac OS, Linux, Android) </p>
                    </div>
                </div>
            </div>
        </section>
        <section id='stores' className='py-5'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 text-center text-white'>
                        <h3> Powering 1000+ websites & Stores WorldWide</h3>
                     </div>
                     <div className='col-lg-3 col-sm-6 mt-4'>
                        <div className='d-flex text-white'>
                            <i class="fa fa-laptop fs-3 me-3" aria-hidden="true"></i>
                            <h6>Various versions have evolved over the years, sometimes by accident</h6></div>
                    </div>
                     <div className='col-lg-3 col-sm-6 mt-4'>
                        <div className='d-flex text-white'>
                            <i class="fa fa-laptop fs-3 me-3" aria-hidden="true"></i>
                            <h6>Various versions have evolved over the years, sometimes by accident</h6></div>
                    </div>
                     <div className='col-lg-3 col-sm-6 mt-4'>
                        <div className='d-flex text-white'>
                            <i class="fa fa-laptop fs-3 me-3" aria-hidden="true"></i>
                            <h6>Various versions have evolved over the years, sometimes by accident</h6></div>
                    </div>
                     <div className='col-lg-3 col-sm-6 mt-4'>
                        <div className='d-flex text-white'>
                            <i class="fa fa-laptop fs-3 me-3" aria-hidden="true"></i>
                            <h6>Various versions have evolved over the years, sometimes by accident</h6></div>
                    </div>
                </div>
            </div>
        </section>
        <section id="features" className='py-5'>
            <div className='container py-md-4'>
                <div className='row justify-content-center'>
                    <div className='col-md-9 text-center'>
                        <h2>Powerfull Unique and Awesome Feature</h2>
                        <p className='my-3'>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                    </div>                
                </div>
                <div className='row justify-content-center'>
                    <div className='col-xl-3 col-lg-4 col-sm-6  text-center mt-5'>
                        <div class="card border-0 shadow py-5 px-3">
                            <div className='img-bg'>
                            <img src="/images/like.webp"  alt="thumbs up icon" className='feature-icon'/>
                            </div>
                            <div class="card-body p-0">
                                <h5>High Subscription Rate</h5>
                                <p class="card-text my-0">Visitors will see a subscription request as soon as they get interested in your site.</p>
                            </div>
                         </div>
                    </div>                
                    <div className='col-xl-3 col-lg-4 col-sm-6  text-center mt-5'>
                        <div class="card border-0 shadow py-5 px-3">
                            <div className='img-bg'>
                            <img src="/images/like.webp"  alt="thumbs up icon" className='feature-icon'/>
                            </div>
                            <div class="card-body p-0">
                                <h5>High Subscription Rate</h5>
                                <p class="card-text my-0">Visitors will see a subscription request as soon as they get interested in your site.</p>
                            </div>
                         </div>
                    </div>                
                    <div className='col-xl-3 col-lg-4 col-sm-6  text-center mt-5'>
                        <div class="card border-0 shadow py-5 px-3">
                            <div className='img-bg'>
                            <img src="/images/like.png"  alt="thumbs up icon" className='feature-icon'/>
                            </div>
                            <div class="card-body p-0">
                                <h5>High Subscription Rate</h5>
                                <p class="card-text my-0">Visitors will see a subscription request as soon as they get interested in your site.</p>
                            </div>
                         </div>
                    </div>                
                    <div className='col-xl-3 col-lg-4 col-sm-6  text-center mt-5'>
                        <div class="card border-0 shadow py-5 px-3">
                            <div className='img-bg'>
                            <img src="/images/like.png"  alt="thumbs up icon" className='feature-icon'/>
                            </div>
                            <div class="card-body p-0">
                                <h5>High Subscription Rate</h5>
                                <p class="card-text my-0">Visitors will see a subscription request as soon as they get interested in your site.</p>
                            </div>
                         </div>
                    </div>                
                    <div className='col-xl-3 col-lg-4 col-sm-6  text-center mt-5'>
                        <div class="card border-0 shadow py-5 px-3">
                            <div className='img-bg'>
                            <img src="/images/like.png"  alt="thumbs up icon" className='feature-icon'/>
                            </div>
                            <div class="card-body p-0">
                            <h5>High Subscription Rate</h5>
                                <p class="card-text my-0">Visitors will see a subscription request as soon as they get interested in your site.</p>
                            </div>
                         </div>
                    </div>                
                    <div className='col-xl-3 col-lg-4 col-sm-6  text-center mt-5'>
                        <div class="card border-0 shadow py-5 px-3">
                            <div className='img-bg'>
                            <img src="/images/like.png"  alt="thumbs up icon" className='feature-icon'/>
                            </div>
                            <div class="card-body p-0">
                            <h5>High Subscription Rate</h5>
                                <p class="card-text my-0">Visitors will see a subscription request as soon as they get interested in your site.</p>
                            </div>
                         </div>
                    </div>                
                    <div className='col-xl-3 col-lg-4 col-sm-6  text-center mt-5'>
                        <div class="card border-0 shadow py-5 px-3">
                            <div className='img-bg'>
                            <img src="/images/like.png"  alt="thumbs up icon" className='feature-icon'/>
                            </div>
                            <div class="card-body p-0">
                            <h5>High Subscription Rate</h5>
                                <p class="card-text my-0">Visitors will see a subscription request as soon as they get interested in your site.</p>
                            </div>
                         </div>
                    </div>                
                                
                    <div className='col-xl-3 col-lg-4 col-sm-6  text-center mt-5'>
                        <div class="card border-0 shadow py-5 px-3">
                            <div className='img-bg'>
                            <img src="/images/like.png"  alt="thumbs up icon" className='feature-icon'/>
                            </div>
                            <div class="card-body p-0">
                            <h5>High Subscription Rate</h5>
                                <p class="card-text my-0">Visitors will see a subscription request as soon as they get interested in your site.</p>
                            </div>
                         </div>
                    </div>                
                </div>
            </div>
        </section>
        <section id='integration' className='bg-light py-5'> 
            <div className='container pt-4'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-lg-5 '>
                         <h2>Limitless Integration</h2>
                        <p className='my-3'>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                        <p className='my-3'>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                        <a href="" className='py-3 grn-btn fs-5 d-inline-block mt-4'>Contact Us</a>
                    </div>
                    <div className='col-lg-7 mt-4 mt-sm-5 mt-lg-0'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='integration-card'>
                                    <img src="/images/wordpress.webp" />
                                    <h5>Wordpress</h5>
                                    <p className='my-3'>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                                </div>   
                                <div className='integration-card'>
                                    <img src="/images/github.webp" />
                                    <h5>GitHub</h5>
                                    <p className='my-3'>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                                </div>                     
                            </div>
                            <div className='col-md-6 mt-md-5'>
                                 <div className='integration-card'>
                                    <img src="/images/shopify.webp" />
                                    <h5>Shopify</h5>
                                    <p className='my-3'>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                                </div>                       
                                 <div className='integration-card'>
                                    <img src="/images/shopify.webp" />
                                    <h5>Shopify</h5>
                                    <p className='my-3'>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                                </div>                       
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </section>
        <section className='testimonial py-5'>
            <div className='container py-md-4'>
                <div className='row justify-content-center'>
                    <div className='col-md-10 text-center'>
                        <h2>Client Testimonial</h2>
                        <p className='my-3'>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                    </div>
                    <div className='col-md-12 mt-5'>
                         <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
                            
                            <div class="carousel-inner rounded position-relative w-75 p-4  p-md-5 shadow mx-auto ">
                            <i class="fa fa-quote-left quote-icon" aria-hidden="true"></i>
                                <div class="carousel-item active " data-bs-interval="10000">
                                    <div class="d-lg-flex align-items-center carousel-box">
                                        <div className="author-detail text-center">
                                            <img src="/images/client.webp"  alt="client img" className='client-img' />
                                            <h5 className='mt-3 mb-0 text-white'>John Doe</h5>
                                            <div className='star-rating'>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                         <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    </div>
                                </div>
                                <div class="carousel-item  " data-bs-interval="10000">
                                    <div class="d-lg-flex align-items-center carousel-box">
                                        <div className="author-detail text-center">
                                            <img src="/images/client.webp"  alt="client img" className='client-img' />
                                            <h5 className='mt-3 mb-0 text-white'>John Stephen</h5>
                                            <div className='star-rating'>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                         <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                         
                                    </div>
                                </div>
                                <div class="carousel-item  " data-bs-interval="10000">
                                    <div class="d-lg-flex align-items-center carousel-box">
                                        <div className="author-detail text-center">
                                            <img src="/images/client.webp"  alt="client img" className='client-img' />
                                            <h5 className='mt-3 mb-0 text-white'>John Mark</h5>
                                            <div className='star-rating'>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                                <i class="fa fa-star checked fs-6" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                         <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    </div>
                                </div>
                                
                                
                                <i class="fa fa-quote-right quote-icon" aria-hidden="true"></i>
                            </div>
                            <button class="carousel-control-prev d-none d-lg-block" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next d-none d-lg-block" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>    
                    </div>
                </div>  
            </div>
        </section>
        <section id='CTA-sec'>
            <div className="container">
                <div className='row justify-content-center'>
                    <div className='col-md-10 text-center text-white'>
                        <h3>Send instant web push notifications to visitors of your site </h3>
                        <p className='mt-3'>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <form>
                            <div className='input-group'>
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Enter Email here' />
                            <button class="btn grn-btn" type="submit">Subscribe</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>        
        </section>
        <Footer/>
        </>
     );
}

export default Home;