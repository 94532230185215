import React, { Component } from 'react';

function Footer() {
    return ( 
        <React.Fragment>
            <div id="footer" className='py-5'>
                <div className='container py-md-4'>
                    <div className="row">
                        <div className='col-sm-4 mb-5'>
                            <h4>Services</h4>
                            <ul className='list-unstyled mb-0 mt-3 footer-links'>
                                <li><a href="">Services 1</a></li>
                                <li><a href="">Services 2</a></li>
                                <li><a href="">Services 3</a></li>
                                <li><a href="">Services 4</a></li>
                                <li><a href="">Services 5</a></li>
                            </ul>
                        </div>                    
                        <div className='col-sm-4 mb-5'>
                            <h4>Important Links</h4>
                            <ul className='list-unstyled mb-0 mt-3 footer-links'>
                                <li><a href="">About Us</a></li>
                                <li><a href="">Services</a></li>
                                <li><a href="">Blog</a></li>
                                <li><a href="">Contact Us</a></li>
                            </ul>
                        </div>                    
                        <div className='col-sm-4 mb-5'>
                            <h4>Contact Us</h4>
                            <ul className='list-unstyled mb-0 mt-3 footer-links'>
                                <li><a href="">About Us</a></li>
                                <li><a href="">Services</a></li>
                                <li><a href="">Blog</a></li>
                                <li><a href="">Contact Us</a></li>
                            </ul>
                        </div>   
                        <div className='col-lg-6 pt-lg-5 border-line text-sm-center text-lg-start py-3 pb-lg-0 '>
                            <ul className='d-sm-flex flex-wrap justify-content-sm-center justify-content-lg-start list-unstyled mb-0'>
                                <li className='me-4'><a href="">Privacy Policy</a></li>
                                <li className='me-4'><a href="">Terms and Condition</a></li>
                                <li><a href="">Disclaimer</a></li>
                            </ul>
                            <p className='mb-0 mt-2 footer-txt'>Copyright © 2020-2022. CouponGini. All rights reserved.</p>
                        </div>                 
                        <div className='col-lg-6 pt-5 border-line text-center text-lg-right '>
                            <ul className='d-flex justify-content-center justify-content-lg-end social-icon list-unstyled mb-0'>
                                <li className='fb'><a href=""><i class="fs-4 fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li className='twitter'><a href=""><i class="fs-4 fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li className='utube'><a href=""><i class="fs-4 fa fa-youtube" aria-hidden="true"></i></a></li>
                                <li className='insta'><a href=""><i class="fs-4 fa fa-instagram" aria-hidden="true"></i></a></li>
                                <li className='pintrest'><a href=""><i class="fs-4 fa fa-pinterest" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>                 
                    </div>                
                </div>            
            </div>
        </React.Fragment>
     );
}

export default Footer;