import React, {useState, Component } from 'react';
import Footer from './Footer';
import Header from './Header';
import Dashboard from './Dashboard';
function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [pageUrl, getPageUrl] = useState("");
    

      const submitForm = () => {
        if(email != "test@gmail.com" || email === ""){
            alert("Email Fields are required");
        }else if(password != "admin" || password === ""){
            alert("please enter password");
        } else{
           
        }
     }
    
    const getEmail = (event) => {
        setEmail(event.target.value)
    }
    const getPwd = (event) => {
        setPassword(event.target.value)
    }
    return ( 
        <React.Fragment>
            <main className='login-main-area'>
                <Header />
                <section id="login-page">
                    <div className='container h-100'>
                        <div className='row h-100 align-items-center'>
                            <div className='col-md-6'>
                                <div className='login-form'>
                                    <a href="#" className='d-block text-center'><img src='/images/couponginilOGO.SVG' className='logo-img'/></a>
                                    <form id='login-form' >
                                        <div class="form-group mt-3 position-relative">
                                            <label for="emailAdd">Email address</label>
                                            <div className='position-relative'>
                                                <i class="fa fa-envelope" aria-hidden="true"></i>
                                                <input type="email" class="form-control mt-1" placeholder='Enter Email Address' id="emailAdd" aria-describedby="emailHelp" required onChange={getEmail}/>
                                            </div>
                                        </div>
                                        <div class="form-group mt-3">
                                            <label for="pwdInput">Password</label>
                                            <div className='position-relative'>
                                                <i class="fa fa-lock" aria-hidden="true"></i>
                                                <input type="password" placeholder='Enter Password' class="form-control mt-1" id="pwdInput" required onChange={getPwd}/>
                                            </div>

                                        </div>
                                    <a href='#' className="forgot-link mt-2 d-block">Forgot Password?</a>
                                    <div className="text-center mt-5">
                                            <button type="submit" class="btn submit-btn d-inline-block"  onClick={submitForm}>Login</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6 text-end">
                                <img src="/images/loginbg.webp" alt="Login Img" className='w-100 login-img' />
                            </div>
                        </div>  
                    </div>
                </section>
                <Footer />
          </main>
        </React.Fragment>
     );
      
    }
  
    

export default Login;