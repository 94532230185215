import React, { Component, useEffect, useState } from 'react';
import GraphData from './Chart';
import Sidebar from './Sidebar';
const url = "http://localhost:4000"
const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MjQ0M2IyYjkyNzhmM2M0YzE3MTliOTAiLCJpYXQiOjE2NDg2NDE5NTh9.OYFRvToG8YvrZ2l-bab4ntGFyoQOM3w7Y1Uxx9D2pWQ"
    function Dashboard() {
        
   
        const [ApiData, setDashBoardData] = useState([]);
        
   
   
        useEffect(()=>{
        async function getDashBoardResponse() {
           try{ let response = await fetch(url+'/v1/get/dashboardStats',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            } )
            console.log(response.status);
            response = await response.json()
           
            setDashBoardData(response)}
            catch(error){
                alert(error)
                alert("Something went wrong")
            }
            
          }

         
         

      
          getDashBoardResponse()

    },[])        

   
        return (
        <React.Fragment>
            <main>
            <div className="container-fluid" style={{paddingLeft:"0", paddingRight:"0", height:"100vh"}}>
                <div className="row notification-page-row">
                   <Sidebar/>
                   <div className="main-body-content">
                       <div className='inner-content'>
                            <div className="inner-content">
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className='box org-grad'>
                                            <div className='detail text-white'>
                                                <h2>{ApiData.totalSubs}</h2>
                                                <h6>Subscribed Users</h6>                                       
                                            </div>
                                            <div className='img-box'>
                                                <img src="/images/user.webp" className='box-icon'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='box active-bg'>
                                            <div className='detail text-white'>
                                                <h2>{ApiData.activeUsers}</h2>
                                                <h6>Active Users</h6>                                       
                                            </div>
                                            <div className='img-box'>
                                                <img src="/images/active-user.webp" className='box-icon'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='box mob-bg'>
                                            <div className='detail text-white'>
                                                <h2>{ApiData.mobileSubs}</h2>
                                                <h6>Mobile Users</h6>                                       
                                            </div>
                                            <div className='img-box'>
                                                <img src="/images/mobile-user.webp" className='box-icon'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='box desk-bg'>
                                            <div className='detail text-white'>
                                                <h2>{ApiData.desktopSubs}</h2>
                                                <h6>Desktop Users</h6>                                       
                                            </div>
                                            <div className='img-box'>
                                                <img src="/images/desk-user.webp" className='box-icon'/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className='col-md-7'>
                                        <div className='card mt-5 border-0 shadow-bg'>
                                            <GraphData coupongini={ApiData.couponginiSubs} avm={ApiData.avmSubs} jobhuntley={ApiData.jobHuntleySubs} mobileSubs={ApiData.mobileSubs} desktopSubs={ApiData.desktopSubs} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            </main>
        </React.Fragment>
      );
}

export default Dashboard;